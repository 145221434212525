import { toMonitored } from '../current-ma/utils/monitoring';
import {
  Callback,
  ControllerConfig,
  MemberInfo,
  RouterConfig,
  SectionData,
} from '../types';

let _config: ControllerConfig | null = null;

export const setConfigGlobally = (config: ControllerConfig) => {
  _config = config;
};

export const getGlobalConfig = () => {
  return _config;
};

export const maOnMSBPlatformExports = {
  hasSocialPages: (onSuccess: Callback, onError: Callback) =>
    toMonitored('publicApi.hasSocialPages', () => {})(),
  getViewedUser: (onSuccess: Callback, onError: Callback) =>
    toMonitored('publicApi.getViewedUser', () => {})(),
  navigateToSection: (sectionData: SectionData, onError: Callback) =>
    toMonitored('publicApi.navigateToSection', () => {})(),
  navigateToMember: (memberInfo: MemberInfo, onError: Callback) =>
    toMonitored('publicApi.navigateToMember', () => {})(),
  getNavigatableRoles: (onError: Callback) =>
    toMonitored('publicApi.getNavigatableRoles', () => {})(),
  getSectionUrl: (sectionData: SectionData, onError: Callback) =>
    toMonitored('publicApi.getSectionUrl', () => {})(),
  getMemberPagePrefix: (
    data: RouterConfig,
    onSuccess: Callback,
    onError: Callback,
  ) => toMonitored('publicApi.getMemberPagePrefix ', () => {})(),
  setNotificationCount: (displayCount: number) =>
    toMonitored('publicApi.setNotificationCount', () => {})(),
  enterPublicProfilePreviewMode: () =>
    toMonitored('publicApi.enterPublicProfilePreviewMode', () => {})(),
  leavePublicProfilePreviewMode: () =>
    toMonitored('publicApi.leavePublicProfilePreviewMode', () => {})(),
  clearMenus: () => toMonitored('publicApi.clearMenus', () => {})(),
  getRoutes: () =>
    toMonitored('publicApi.getRoutes', () => _config?.config.routes ?? [])(),
};
