import { CreateControllerFn } from '@wix/yoshi-flow-editor';

import { setConfigGlobally } from '../../../ma-v2';
import { ControllerConfig } from '../../../types';

export const controllerMAV2: CreateControllerFn = async ({
  controllerConfig,
}) => {
  setConfigGlobally(controllerConfig as ControllerConfig);

  return {
    pageReady() {},
  };
};
