import { InitAppForPageFn } from '@wix/yoshi-flow-editor';
import {
  initializeMonitoring,
  toMonitored,
} from './current-ma/utils/monitoring';
import {
  currentMAPlatformExports,
  fetchMenusData,
  initApplication,
  redirectIfURLIsInvalid,
} from './current-ma';
import { getGlobalConfig, maOnMSBPlatformExports } from './ma-v2';

const getViewerPlatformExports = async () => {
  const config = getGlobalConfig();
  const isMembersAreaV2Enabled = config?.config.isMembersAreaV2;

  return isMembersAreaV2Enabled
    ? maOnMSBPlatformExports
    : currentMAPlatformExports;
};

export const initAppForPage: InitAppForPageFn = async (
  initParams,
  platformApis,
  wixCodeApi,
  platformServices,
  flowApi,
) => {
  initializeMonitoring(initParams, platformServices, flowApi.experiments);
  const { httpClient } = flowApi;
  await toMonitored('initAppForPage', () =>
    initApplication(
      initParams as any,
      platformApis as any,
      wixCodeApi,
      httpClient,
      flowApi.experiments,
    ),
  )();
  await redirectIfURLIsInvalid(wixCodeApi, platformApis);

  return fetchMenusData({ appParams: initParams, wixCodeApi, flowApi });
};

export const exports = async () => {
  return getViewerPlatformExports();
};
