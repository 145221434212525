import {
  ControllerFlowAPI,
  Experiments,
  IHttpClient,
  IPlatformAPI,
  IUser,
  IWixAPI,
  ViewerScriptFlowAPI,
} from '@wix/yoshi-flow-editor';

import { parseConfigItems } from './config-items-parser';
import { init as createUserService, UserService } from './services/user';
import { buildCurrentPath } from './services/location';
import { renderLoginMenus, renderMembersMenus } from './services/menu-renderer';
import { log, toMonitored } from './utils/monitoring';
import { GroupsService, initGroupsService } from './services/groups';
import { getPagesFromRouters } from './services/page-mapper';
import { initInMemoryCacheService } from './services/cache';
import { getMemoryStorage, setMemoryStorage } from './services/memory-storage';
import { PublicAPI } from './public-api';
import {
  AppData,
  CacheService,
  Callback,
  ControllerConfig,
  Member,
  MemberInfo,
  RawRouter,
  ReturnedRouterData,
  RouterConfig,
  SectionData,
  Storage,
  WixCodeApi,
} from '../types';
import { INITIAL_WARMUP_DATA } from '../constants';

let _config: ControllerConfig;
let publicApi: PublicAPI;
let userService: UserService;
let groupsService: GroupsService;
let cacheService: CacheService;

export const initApplication = async (
  {
    appDefinitionId,
    routerReturnedData,
    appRouters = [],
  }: {
    appDefinitionId: string;
    routerReturnedData: ReturnedRouterData;
    appRouters?: RawRouter[];
  },
  { storage }: { storage: Storage },
  wixCodeApi: WixCodeApi,
  httpClient: IHttpClient,
  experiments: Experiments,
) => {
  userService = createUserService(wixCodeApi);
  cacheService = initInMemoryCacheService(storage);
  groupsService = initGroupsService(httpClient, experiments);
  publicApi = new PublicAPI({
    appRouters,
    routerReturnedData,
    wixCodeApi,
    cacheService,
  });
  setMemoryStorage(storage.memory);
  await userService.setCurrentUser(wixCodeApi.user.currentUser, httpClient);

  if (!routerReturnedData) {
    return Promise.resolve();
  }

  const instance = wixCodeApi.site.getAppToken?.(appDefinitionId);
  const slugs =
    (routerReturnedData.memberData && routerReturnedData.memberData.slugs) ||
    [];
  const viewedUserId =
    (routerReturnedData.memberData &&
      routerReturnedData.memberData.memberContactId) ||
    routerReturnedData.userId;
  const primarySlug = slugs.find((slug) => slug.primary);
  const viewedUserSlug = (primarySlug && primarySlug.name) || viewedUserId;
  const viewedUserData: Member = { id: viewedUserId!, slug: viewedUserSlug! };

  userService.setViewedUser(viewedUserData);
  userService.setRoles(routerReturnedData.roles || {});

  if (routerReturnedData.roles) {
    cacheService.setRoles(instance!, viewedUserId, routerReturnedData.roles);
  }
};

const getMenuCounters = async (
  instance: string,
  isSSR: boolean,
  httpClient: IHttpClient,
) => {
  if (isSSR) {
    return { currentUserCounters: undefined, viewedUserCounters: undefined };
  }

  const currentUser = userService.getCurrentUser();
  const viewedUser = userService.getViewedUser();

  const getUserMenuCounters = async (user: Member) => {
    if (cacheService.hasNumbers(instance, user.id)) {
      return cacheService.getNumbers(instance, user.id);
    }

    const menuCounters = await userService.fetchMenuCounters(user, httpClient);
    cacheService.setNumbers(instance, user.id, menuCounters);

    return menuCounters;
  };

  const isSameSessionUser = viewedUser.id === currentUser.id;
  const currentUserMenuCountersPromise = getUserMenuCounters(currentUser);

  const [currentUserCounters, viewedUserCounters] = await Promise.all([
    currentUserMenuCountersPromise,
    isSameSessionUser
      ? currentUserMenuCountersPromise
      : viewedUser.id
      ? getUserMenuCounters(viewedUser)
      : {},
  ]);

  return { currentUserCounters, viewedUserCounters };
};

const getRoles = async (instance: string, httpClient: IHttpClient) => {
  const currentUser = userService.getCurrentUser();
  const viewedUser = userService.getViewedUser();

  if (cacheService.hasRoles(instance, viewedUser?.id)) {
    return cacheService.getRoles(instance, viewedUser?.id)!;
  }

  const roles = await userService.fetchRoles(
    viewedUser.id,
    currentUser.id,
    httpClient,
  );
  cacheService.setRoles(instance, viewedUser?.id, roles);

  return roles;
};

export async function fetchMenusData({
  wixCodeApi,
  appParams,
  flowApi,
}: Pick<ControllerConfig, 'wixCodeApi' | 'appParams'> & {
  flowApi: ViewerScriptFlowAPI;
}) {
  const { httpClient } = flowApi;
  const currentUser = userService.getCurrentUser();
  const userRoles = userService.getRoles();
  const viewedUser = userService.getViewedUser();

  const needToFetchRoles =
    currentUser.loggedIn! && Object.keys(userRoles).length === 0;
  const santaMembersToken = wixCodeApi.site.getAppToken?.(
    appParams.appDefinitionId,
  );
  const isSSR = wixCodeApi.window.rendering.env === 'backend';
  const parsedRouters = ((appParams.appRouters as any[]) || []).map(
    (router) => ({
      ...router,
      config: JSON.parse(router.config),
    }),
  );
  const parsedRoutersConfigs = parsedRouters.map((router) => router.config);

  const fetchInitialData = () =>
    Promise.all([
      getMenuCounters(santaMembersToken!, isSSR, httpClient),
      needToFetchRoles ? getRoles(santaMembersToken!, httpClient) : {},
      groupsService.getPermittedPagesMap(
        getPagesFromRouters(parsedRouters),
        wixCodeApi.window.viewMode,
      ),
    ]);

  let initialData;
  if (flowApi.environment.isSSR) {
    initialData = await fetchInitialData();
    wixCodeApi.window.warmupData.set(INITIAL_WARMUP_DATA, initialData);
  } else {
    const warmupData = wixCodeApi.window.warmupData.get(INITIAL_WARMUP_DATA);
    initialData = warmupData || (await fetchInitialData());
  }

  const [counters, roles, permittedPagesMap] = initialData;

  if (needToFetchRoles) {
    userService.setRoles(roles);
  }

  const parsedConfigItems = parseConfigItems(appParams as any);
  const currentUserRoles = userService.getRoles()[currentUser.id] || [];
  const viewedUserRoles = userService.getRoles()[viewedUser.id] || [];
  const publicRouter = parsedRouters.find(
    (router) => router.config.type === 'public',
  );
  const publicRouterPrefix = publicRouter?.prefix ?? '';

  return {
    counters,
    permittedPagesMap,
    parsedRouters,
    parsedRoutersConfigs,
    parsedConfigItems,
    currentUserRoles,
    viewedUserRoles,
    publicRouterPrefix,
  };
}

export function wrappedRenderMemberMenus(
  config: ControllerConfig,
  appData: AppData,
) {
  const {
    wixCodeApi,
    $w,
    essentials: { experiments },
  } = config;
  const viewedUser = userService.getViewedUser();
  const currentUser = userService.getCurrentUser();
  const isMobile = wixCodeApi.window.formFactor === 'Mobile';
  const memoryStorage = getMemoryStorage();

  const {
    permittedPagesMap,
    parsedRoutersConfigs,
    viewedUserRoles,
    parsedConfigItems,
    counters: { viewedUserCounters },
    publicRouterPrefix,
  } = appData;

  toMonitored('renderMembersMenuItems', () =>
    renderMembersMenus({
      $w,
      wixCodeApi,
      parsedRoutersConfigs,
      viewedUserRoles,
      viewedUser,
      currentUser,
      appsCounters: viewedUserCounters,
      parsedConfigItems,
      memoryStorage,
      publicRouterPrefix,
      permittedPagesMap,
      experiments,
      isMobile,
    }),
  )();
}

export function wrappedRenderLoginMenus(
  config: ControllerConfig,
  appData: AppData,
  flowAPI: ControllerFlowAPI,
) {
  const {
    $w,
    essentials: { experiments },
    wixCodeApi,
  } = config;
  const currentUser = userService.getCurrentUser();
  const isMobile = wixCodeApi.window.formFactor === 'Mobile';
  const memoryStorage = getMemoryStorage();

  const {
    permittedPagesMap,
    parsedRoutersConfigs,
    counters: { currentUserCounters },
    publicRouterPrefix,
    currentUserRoles,
  } = appData;

  toMonitored('renderLoginMenuItems', () =>
    renderLoginMenus({
      $w,
      parsedRoutersConfigs,
      currentUserRoles,
      currentUser,
      appsCounters: currentUserCounters,
      memoryStorage,
      publicRouterPrefix,
      permittedPagesMap,
      experiments,
      isMobile,
      wixCodeApi,
      flowAPI,
    }),
  )();
}

export function renderMenus(
  config: ControllerConfig,
  appData: AppData,
  flowAPI: ControllerFlowAPI,
) {
  wrappedRenderLoginMenus(config, appData, flowAPI);
  wrappedRenderMemberMenus(config, appData);
}

// Old method, making sure whether it is still needed with logs
export const redirectIfURLIsInvalid = (
  wixCodeApi: IWixAPI,
  platformAPIs: IPlatformAPI,
) => {
  const viewedUser = userService.getViewedUser();

  if (wixCodeApi.window.viewMode === 'Site') {
    const url = buildCurrentPath(wixCodeApi);
    const urlWithSlug = userService.replaceUserPatternWithSlug(url, viewedUser);
    if (url !== urlWithSlug) {
      const tags = { viewerName: platformAPIs.bi?.viewerName! };
      log('Deprecation check: redirect', {
        tags,
        extra: { from: url, to: urlWithSlug },
      });
      return wixCodeApi.location.to?.(urlWithSlug);
    }
  }
};

export const setConfigGlobally = (config: ControllerConfig) => {
  _config = config;
};

export const setCurrentUserGlobally = (
  loggedInUser: IUser,
  httpClient: IHttpClient,
) => userService.setCurrentUser(loggedInUser, httpClient);

export const currentMAPlatformExports = {
  hasSocialPages: (onSuccess: Callback, onError: Callback) =>
    toMonitored('publicApi.hasSocialPages', () =>
      publicApi.hasSocialPages(onSuccess, onError),
    )(),
  getViewedUser: (onSuccess: Callback, onError: Callback) =>
    toMonitored('publicApi.getViewedUser', () =>
      publicApi.getViewedUser(onSuccess, onError),
    )(),
  navigateToSection: (sectionData: SectionData, onError: Callback) =>
    toMonitored('publicApi.navigateToSection', () =>
      publicApi.navigateToSection(sectionData, onError),
    )(),
  navigateToMember: (memberInfo: MemberInfo, onError: Callback) =>
    toMonitored('publicApi.navigateToMember', () =>
      publicApi.navigateToMember(memberInfo, onError),
    )(),
  getNavigatableRoles: (onError: Callback) =>
    toMonitored('publicApi.getNavigatableRoles', () =>
      publicApi.getNavigatableRoles(),
    )(),
  getSectionUrl: (sectionData: SectionData, onError: Callback) =>
    toMonitored('publicApi.getSectionUrl', () =>
      publicApi.getSectionUrl(sectionData, onError),
    )(),
  getMemberPagePrefix: (
    data: RouterConfig,
    onSuccess: Callback,
    onError: Callback,
  ) =>
    toMonitored('publicApi.getMemberPagePrefix ', () =>
      publicApi.getMemberPagePrefix(data, onSuccess, onError),
    )(),
  setNotificationCount: (displayCount: number) =>
    toMonitored('publicApi.setNotificationCount', () =>
      publicApi.setNotificationCount(displayCount),
    )(),
  enterPublicProfilePreviewMode: () =>
    toMonitored('publicApi.enterPublicProfilePreviewMode', () =>
      publicApi.enterPublicProfilePreviewMode({
        userService,
        config: _config,
      }),
    )(),
  leavePublicProfilePreviewMode: () =>
    toMonitored('publicApi.leavePublicProfilePreviewMode', () =>
      publicApi.leavePublicProfilePreviewMode({
        userService,
        config: _config,
      }),
    )(),
  clearMenus: () =>
    toMonitored('publicApi.clearMenus', () =>
      publicApi.clearMenus({ config: _config }),
    )(),
};
