import { CreateControllerFn } from '@wix/yoshi-flow-editor';

import { ControllerConfig } from '../../types';
import { controllerCurrentMA } from './current-ma';
import { controllerMAV2 } from './ma-v2';

type Config = ControllerConfig['config'];

const getGlobalController = (config: Config) => {
  return config.isMembersAreaV2 ? controllerMAV2 : controllerCurrentMA;
};

const createController: CreateControllerFn = async (props) => {
  const { config } = props.controllerConfig;
  const globalController = getGlobalController(config as Config);

  return globalController(props);
};

export default createController;
